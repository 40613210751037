<div class='contact'>
    <h2>Contact Me</h2>
    <p>Need a hand? Let's turn your ideas into something tangible!</p>
    <br/>
    <hr/>
    <table>
        <tr>
            <p>Email:</p>
            <th><a href='mailto:itsevalieu@gmail.com'>itsevalieu@gmail.com</a></th>
        </tr>
        <tr>
            <p>LinkedIn:</p>
            <th><a href='https://www.linkedin.com/in/eva-lieu/' target='_blank'>eva-lieu</a></th>
        </tr>
        <tr>
            <p>Github:</p>
            <th><a href='https://github.com/itsevalieu' target='_blank'>itsevalieu</a></th>
        </tr>
        <tr>
            <p>Twitter:</p>
            <th><a href='https://twitter.com/itsevalieu?lang=en' target='_blank'>@itsevalieu</a></th>
        </tr>
    </table>
</div>