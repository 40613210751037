<a routerLink='/' class='person'>
  <img class='person__avatar' src='assets/profile.jpg' alt='profile'/>
  <h1 class='person__title'>{{ appTitle }}</h1>
  <p class='person__subtitle'>Web Developer</p>
</a>
<nav class='nav'>
  <ul class='social-links'>
    <div class='nav__social'>
        <li><a href='mailto:itsevalieu@gmail.com'><img src='assets/mail.png' alt='mail'></a></li>
        <li><a href='https://www.linkedin.com/in/eva-lieu/' target='_blank'><img src='assets/linkedin.png' alt='linkedin'></a></li>
        <li><a href='https://github.com/itsevalieu' target='_blank'><img src='assets/github.png' alt='github'></a></li>
        <li><a href='https://twitter.com/itsevalieu?lang=en' target='_blank'><img src='assets/twitter.png' alt='twitter'></a></li>
    </div>
    <div class='nav__menu'>
        <li><button (click)='toggleNav()'><img src='assets/hamburger.png' alt='hamburger'></button></li>
    </div>
  </ul>
  <ul class='nav-links' [ngClass]= "{
    'nav__links-show': navVisible,
    'nav__links-hide': !navVisible
  }">
    <li><a routerLink='/portfolio'>Portfolio</a></li>
    <li><a href='https://drive.google.com/open?id=1QcqsxtnclGiPzTpcATpFEI_q34wkpa6T' target='_blank'>Resume</a></li>
    <li><a routerLink='/contact'>Contact Me</a></li>
  </ul>
</nav>