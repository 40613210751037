<div class='portfolio'>
  <h2>Portfolio</h2>
  <p>Check out my work below. Unless otherwise stated, all the back-end and front-end were done by me.</p>
  <br/>
  
  <ul class='timeline'>
    <li class='project'>
      <div class='project__date'>Now</div>
      <div class='project__description'>
        <div class='project__text'>
            <h3 class='project__title'>Planet B</h3> 
            <p class='project__subtitle'>MongoDB hackathon project - exoplanet transit database for astronomers</p>
            <p class='project__summary'>Allows the global community of astronomers to access a database of exoplanet transit data in a readable format.
              <br/><br/>Not to be dramatic, but humanity is doomed for extinction, albeit, due to climate change, world war, or near-earth asteroids. In order to preserve the culture and civilization of humanity, the need of becoming a multi-planetary species will be inevitable. Discovering and studying habitable Earth-like exoplanets provides insight into our own planet as well as the possibility of finding a backup home for future generations to come. This database is designed to preserve the scientific work pertaining to exoplanet transit data, contributed and maintained by a global community of astronomers, using MongoDB technology.
              <br/><br/>As the lead web developer, I collaborated with my teammates to create user stories, wireframe the components, prioritize features, and resolve github issues. I worked on the React side of the project, where I set up the routes, fetched the data, and styled the components. I also set up the Node.js backend. 
            </p>
            <div>
              <a href='https://devpost.com/software/planet-b' target='_blank'><button class='project__button'>DEVPOST</button></a>
              <a href='https://github.com/itsevalieu/planetb' target='_blank'><button class='project__button'>GITHUB</button></a>
            </div>
            <div class='project__tech'>
              <p>TECHNOLOGIES</p>
              <ul class='tech__list'>
                <li class='tech__item'>Javascript</li>
                <li class='tech__item'>react</li>
                <li class='tech__item'>node.js</li>
                <li class='tech__item'>express.js</li>
                <li class='tech__item'>mongodb atlas</li>
                <li class='tech__item'>mongodb stitch</li>
                <li class='tech__item'>styled-components</li>
                <li class='tech__item'>react router</li>
                <li class='tech__item'>travis-ci</li>
                <li class='tech__item'>cypress</li>
              </ul>
            </div>
        </div>
        <div class='project__demo'>
          <img src='assets/planetb.jpg' alt='demo'/>
          <img src='assets/planetb2.jpg' alt='demo'/>
        </div>
      </div>
    </li>
    <li class='project'>
      <div class='project__date'>2019</div>
      <div class='project__description'>
        <div class='project__text'>
            <h3 class='project__title'>Learn Teach Code</h3> 
            <p class='project__subtitle'>Open-source contribution to my favorite programming meetup!</p>
            <p class='project__summary'>I worked on the first iteration of the meetup event week view feature, allowing users to toggle between list and week view for readability. More features to come. In the meanwhile, come visit the Alhambra Learn Teach Code meetup site I cohost!</p>
            <div>
              <a href='https://learnteachcode.org/' target='_blank'><button class='project__button'>DEMO</button></a>
              <a href='https://github.com/itsevalieu/learnteachcode.github.io' target='_blank'><button class='project__button'>GITHUB</button></a>
            </div>
            <div class='project__tech'>
              <p>TECHNOLOGIES</p>
              <ul class='tech__list'>
                <li class='tech__item'>HTML</li>
                <li class='tech__item'>CSS</li>
                <li class='tech__item'>Javascript</li>
                <li class='tech__item'>jquery</li>
                <li class='tech__item'>meetup api</li>
              </ul>
            </div>
        </div>
        <!-- <div class='project__demo'>
          <img src='assets/lala.png' alt='demo'/>
        </div> -->
      </div>
    </li>
    <li class='project'>
      <div class='project__date'>2019</div>
      <div class='project__description'>
        <div class='project__text'>
            <h3 class='project__title'>NodeWaste</h3> 
            <p class='project__subtitle'>AT&T SHAPE hackathon project: scaling zero waste best IOT or interactive solution.</p>
            <p class='project__summary'>NodeWaste educates users to reduce waste material contamination, allows users to join campaign in direct effort to divert waste from landfills, incentivises users to be more active in this new way of life respecting the planet.
              <br/><br/> As the lead developer, I set up the React frontend and Google maps api connection. I also styled the components from wireframes designed by our team designers. I worked with the project manager to consider the scope for the project.
            </p>
            <div>
              <a href='https://devpost.com/software/nodewaste' target='_blank'><button class='project__button'>DEVPOST</button></a>
              <a href='https://github.com/itsevalieu/lala' target='_blank'><button class='project__button'>GITHUB</button></a>
            </div>
            <div class='project__tech'>
              <p>TECHNOLOGIES</p>
              <ul class='tech__list'>
                <li class='tech__item'>Javascript</li>
                <li class='tech__item'>react</li>
                <li class='tech__item'>Google maps api</li>
                <li class='tech__item'>node.js</li>
                <li class='tech__item'>express</li>
              </ul>
            </div>
        </div>
        <div class='project__demo'>
          <img src='assets/nodewaste1.png' alt='demo'/>
          <img src='assets/nodewaste2.png' alt='demo'/>
        </div>
      </div>
    </li>
    <li class='project'>
      <div class='project__date'>2019</div>
      <div class='project__description'>
        <div class='project__text'>
            <h3 class='project__title'>Angular Portfolio</h3> 
            <p class='project__subtitle'>Best way to learn Angular? By making a portfolio.</p>
            <p class='project__summary'>Check out all of my web development work by scrolling down the timeline! I've also got work that is currently in progress, and I'm so excited about them!</p>
            <div>
              <a href='/evalieu/' target='_blank'><button class='project__button'>DEMO</button></a>
              <a href='https://github.com/itsevalieu/portfolio' target='_blank'><button class='project__button'>GITHUB</button></a>
            </div>
            <div class='project__tech'>
              <p>TECHNOLOGIES</p>
              <ul class='tech__list'>
                <li class='tech__item'>Angular</li>
                <li class='tech__item'>CSS</li>
                <li class='tech__item'>SCSS</li>
                <li class='tech__item'>TypeScript</li>
                <li class='tech__item'>JavaScript</li>
              </ul>
            </div>
        </div>
        <!-- <div class='project__demo'>
          <img src='/' alt='demo'/>
        </div> -->
      </div>
    </li>
    <li class='project'>
      <div class='project__date'>2019</div>
      <div class='project__description'>
        <div class='project__text'>
            <h3 class='project__title'>Andrew's Dating Profile</h3> 
            <p class='project__subtitle'>Mobile-responsive single-page profile</p>
            <p class='project__summary'>How better to show your love for a family member than to make him a dating profile? All right, maybe it was just for the giggles.</p>
            <div>
              <a href='https://no1bachelorandrewla.netlify.com/' target='_blank'><button class='project__button'>DEMO</button></a>
              <a href='https://github.com/itsevalieu/lala' target='_blank'><button class='project__button'>GITHUB</button></a>
            </div>
            <div class='project__tech'>
              <p>TECHNOLOGIES</p>
              <ul class='tech__list'>
                <li class='tech__item'>HTML</li>
                <li class='tech__item'>CSS</li>
                <li class='tech__item'>Javascript</li>
                <li class='tech__item'>netlify</li>
              </ul>
            </div>
        </div>
        <div class='project__demo'>
          <img src='assets/lala.png' alt='demo'/>
        </div>
      </div>
    </li>
    <li class='project'>
      <div class='project__date'>2017-2018</div>
      <div class='project__description'>
        <div class='project__text'>
            <h3 class='project__title'>Teafographic Encyclopedia</h3> 
            <p class='project__subtitle'>A tea and herb encyclopedia for the tea addict.</p>
            <p class='project__summary'>Mobile-responsive with a search filter for a custom-made tea api database. Need to figure out which teas are good for what? Check out the handy dandy tea encyclopedia!</p>
            <div>
              <a href='https://teafographic.herokuapp.com/encyclopedia' target='_blank'><button class='project__button'>DEMO</button></a>
              <a href='https://github.com/itsevalieu/project-lotus' target='_blank'><button class='project__button'>GITHUB</button></a>
            </div>
            <div class='project__tech'>
              <p>TECHNOLOGIES</p>
              <ul class='tech__list'>
                <li class='tech__item'>React</li>
                <li class='tech__item'>CSS/SCSS</li>
                <li class='tech__item'>Express.js</li>
                <li class='tech__item'>Node.js</li>
                <li class='tech__item'>MongoDB</li>
                <li class='tech__item'>Mongoose</li>
                <li class='tech__item'>JavaScript</li>
                <li class='tech__item'>netlify</li>
              </ul>
            </div>
        </div>
        <div class='project__demo'>
          <img src='assets/teafoencyclo.png' alt='demo'/>
        </div>
      </div>
    </li>
    <li class='project'>
      <div class='project__date'>2017-2018</div>
      <div class='project__description'>
        <div class='project__text'>
            <h3 class='project__title'>Minimalist Landing Page</h3> 
            <p class='project__subtitle'>A minimalist landing page for a tea subscription product</p>
            <p class='project__summary'>Mobile-responsive landing page with custom css.</p>
            <div>
              <a href='https://teafographic.herokuapp.com/' target='_blank'><button class='project__button'>DEMO</button></a>
              <a href='https://github.com/itsevalieu/project-lotus' target='_blank'><button class='project__button'>GITHUB</button></a>
            </div>
            <div class='project__tech'>
              <p>TECHNOLOGIES</p>
              <ul class='tech__list'>
                <li class='tech__item'>React</li>
                <li class='tech__item'>CSS/SCSS</li>
                <li class='tech__item'>Express.js</li>
                <li class='tech__item'>Node.js</li>
                <li class='tech__item'>MongoDB</li>
                <li class='tech__item'>Mongoose</li>
                <li class='tech__item'>JavaScript</li>
                <li class='tech__item'>netlify</li>
              </ul>
            </div>
        </div>
        <div class='project__demo'>
          <img src='assets/teafolanding.png' alt='demo'/>
        </div>
      </div>
    </li>
    <li class='project'>
      <div class='project__date'>2017-2018</div>
      <div class='project__description'>
        <div class='project__text'>
            <h3 class='project__title'>E.L Bulletin</h3> 
            <p class='project__subtitle'>A bulletin board for my writing portfolio with filter functionality for creative writing, literary analysis, and research papers.</p>
            <p class='project__summary'></p>
            <div>
              <a href='https://elbulletin-db.herokuapp.com/' target='_blank'><button class='project__button'>DEMO</button></a>
              <a href='https://github.com/itsevalieu/elbulletin' target='_blank'><button class='project__button'>GITHUB</button></a>
            </div>
            <div class='project__tech'>
              <p>TECHNOLOGIES</p>
              <ul class='tech__list'>
                <li class='tech__item'>React</li>
                <li class='tech__item'>CSS</li>
                <li class='tech__item'>Node.js</li>
                <li class='tech__item'>Express.js</li>
                <li class='tech__item'>MongoDB</li>
                <li class='tech__item'>JavaScript</li>
                <li class='tech__item'>Heroku</li>
              </ul>
            </div>
        </div>
        <div class='project__demo'>
          <img src='assets/elbulletin.png' alt='demo'/>
        </div>
      </div>
    </li>
    <li class='project'>
      <div class='project__date'>2017</div>
      <div class='project__description'>
        <div class='project__text'>
            <h3 class='project__title'>Bird Pick Tea Drink Generator</h3> 
            <p class='project__subtitle'>Random drink generator for Bird Pick Tea & Herb drink menu</p>
            <p class='project__summary'>Ever had trouble deciding on a drink because there are just sooo many options on the BP menu? Fret not! For there is a solution to that. Get the customer line going with a click of a button!</p>
            <div>
              <a href='https://birdpicktea.com/pages/drinkgenerator' target='_blank'><button class='project__button'>DEMO</button></a>
            </div>
            <div class='project__tech'>
              <p>TECHNOLOGIES</p>
              <ul class='tech__list'>
                <li class='tech__item'>JavaScript</li>
                <li class='tech__item'>liquid</li>
                <li class='tech__item'>html</li>
                <li class='tech__item'>css</li>
                <li class='tech__item'>shopify</li>
              </ul>
            </div>
        </div>
        <div class='project__demo'>
          <img src='assets/bpgenerator.png' alt='demo'/>
        </div>
      </div>
    </li>
    <li class='project'>
      <div class='project__date'>2016</div>
      <div class='project__description'>
        <div class='project__text'>
            <h3 class='project__title'>Jukebox</h3> 
            <p class='project__subtitle'>A music playlist queue with a community chatroom</p>
            <p class='project__summary'>A team project done way back in the day. Worked on the front-end portion.</p>
            <div>
              <a href='https://github.com/itsevalieu/jukebox' target='_blank'><button class='project__button'>GITHUB</button></a>
            </div>
            <div class='project__tech'>
              <p>TECHNOLOGIES</p>
              <ul class='tech__list'>
                <li class='tech__item'>JavaScript</li>
                <li class='tech__item'>Bootstrap</li>
                <li class='tech__item'>firebase</li>
                <li class='tech__item'>jquery</li>
                <li class='tech__item'>itunes api</li>
              </ul>
            </div>
        </div>
        <!-- <div class='project__demo'>
          <img src='/' alt='demo'/>
        </div> -->
      </div>
    </li>
  </ul>
</div>