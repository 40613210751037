<div class="hero">
  <div>
    <h2>Hi, I'm Eva,</h2>
    <p>a web developer specializing in</p>
    <p>front-end and back-end development.</p>
  </div>

  <div class="hero__buttons">
    <a routerLink="/portfolio"
      ><button class="hero__button">portfolio</button></a
    >
    <br />
    <a routerLink="/contact"><button class="hero__button">contact</button></a>
  </div>
</div>
